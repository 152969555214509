<template>
  <div class="stepContent commonClass">
    <h3 class="titHead">动物合笼</h3>
    <div class="detail">
      <el-form ref="addForm" size="small" :model="cage" class="addForm" label-suffix=":"
               :disabled="activeInfo.status>31?true:false"
               label-width="80px">
        <h3 class="tit">动物合笼(申请笼舍数量：{{ activeInfo.cageNum }})</h3>
        <el-tabs v-model="cage.nowNumber" class="tabsCage" type="card">
          <el-tab-pane
              :key="key"
              v-for="(item,key) in cage.cageList"
              :label="key"
              :name="key"
          >
            <div class="desc" v-if="activeInfo.isNotApproval==0">
              <span>笼架编号：{{ item.frameNo }}</span>
            </div>
            <el-table
                :data="item" border
                class="tableMember" max-height="500"
                :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
                style="width: calc(100% - 12px);font-size:14px;color:#333">
              <el-table-column label="耳号" width="150">
                <template slot-scope="scope">
                  <!--                  @change="changeOverbit($event,scope.row,scope.$index)"-->
                  <template v-if="scope.row.aniQuarResult.show&& scope.row.id == ''">
                    <el-select v-model="scope.row.aniQuarResult.overbit" filterable size="small" placeholder="请选择耳号"
                               v-if="activeInfo.isLabAni == 0">
                      <el-option
                          v-for="(item,i) in overbitList"
                          :key="i"
                          :label="item.overbit"
                          :value="String(item.id)"
                          :disabled="item.disabled">
                      </el-option>
                    </el-select>
                    <el-input v-else v-model="scope.row.overbit" clearable placeholder="请输入耳号"
                              class="smallWidth"></el-input>
                  </template>
                  <span v-else>{{
                      scope.row.aniQuarResult.overbit ? scope.row.aniQuarResult.overbit : scope.row.aniQuarResult.id
                    }}</span>
                </template>
              </el-table-column>
              <el-table-column
                  label="基因类型"
                  v-if="activeInfo.aniOrder.isGene==1"
                  show-overflow-tooltip>
                <el-table-column :label="item.title" v-for="(item,ind) in typeList" :key="ind">
                  <template slot-scope="scope">
                    <div v-if="scope.row.aniQuarResult.typeList.length>0">{{
                        scope.row.aniQuarResult.typeList[ind].type ? scope.row.aniQuarResult.typeList[ind].type : ' '
                      }}
                    </div>
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column
                  label="性别" width="100"
                  show-overflow-tooltip>
                <template slot-scope="scope">
                  <div>{{ scope.row.aniQuarResult.gender == 0 ? '♀' : '♂' }}</div>
                </template>
              </el-table-column>
              <el-table-column
                  label="出生日期"
                  width="200">
                <template slot-scope="scope">
                  <template v-if="scope.row.show && activeInfo.isLabAni == 1">
                    <el-date-picker :picker-options="pickerOptions" style="width: 160px"
                                    @change="selectBir($event,scope.row.aniQuarResult,scope.$index)"
                                    v-model="scope.row.birth_date" size="small"
                                    type="date"
                                    placeholder="请选择出生日期">
                    </el-date-picker>
                  </template>
                  <template v-else-if="scope.row.aniQuarResult.birthDate">
                    {{ scope.row.aniQuarResult.birthDate |  formatDay }}
                  </template>
                  <template v-else>无</template>
                </template>
              </el-table-column>
              <el-table-column
                  label="周龄" prop="weekDays"
                  show-overflow-tooltip>
                <template slot-scope="scope">
                  <div>{{ scope.row.weekDays ? scope.row.weekDays : 0 }}</div>
                </template>
              </el-table-column>
              <el-table-column label="备注" min-width="100" show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-input v-if="scope.row.aniQuarResult.show" size="small" placeholder="备注"
                            v-model="scope.row.remark"></el-input>
                  <span v-else>{{ scope.row.remark }}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="150" fixed="right">
                <template slot-scope="scope">
                  <div class="editColor">
                    <el-button type="text" v-if="!scope.row.aniQuarResult.show" @click="edit(scope.row,scope.$index)">
                      修改
                    </el-button>
                    <el-button type="text" v-if="scope.row.aniQuarResult.show" @click="save(scope.row,scope.$index)">
                      保存
                    </el-button>
                    <!--                    <el-button type="text" v-if="!scope.row.aniQuarResult.show" @click="copy(scope.row,scope.$index)">
                                          复制
                                        </el-button>-->
                    <el-button type="text" @click="deleteA(scope.row,scope.$index)">删除</el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
        <div class="fromSave" v-if="activeInfo.status===31">
          <el-button type="primary" size="small" @click="cageSubmit('1')">合笼完成</el-button>
<!--          <el-button plain type="primary" size="small" @click="cageSubmit('0')">保存</el-button>-->
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "pointFinish",
  props: ['activeInfo', 'status'],
  data() {
    return {
      cage: {
        cageList: {},
        nowNumber: '',
        tabIndex: 0
      },
      overbitList: [],//耳号列表
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e7;
        },
      },
      typeList: [],
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.$get('/mate/aniCage/submit/' + this.activeInfo.id, {isLabAni: this.activeInfo.isLabAni}).then(res => {
        if (res.status == 200) {
          this.cage.cageList = res.data
          let arr = []
          let lists = [];
          for (const key in res.data) {
            arr.push(key)
            res.data[key].forEach(item => {
              item.aniQuarResult.show = false
              item.aniQuarResult.typeList = JSON.parse(item.aniQuarResult.gene);
              lists = item.aniQuarResult.gene ? JSON.parse(item.aniQuarResult.gene) : [];
              this.selectBir(item.aniQuarResult.birthDate, item)
            })
          }
          this.typeList = lists;// 基因类型
          this.cage.nowNumber = arr[0]
          if (this.activeInfo.isLabAni === 0) {
            this.getOvbit()
          }
        }
      })
    },
    // 获取耳号
    getOvbit() {
      this.$get('/ani/quarResult/' + this.activeInfo.id).then(res => {
        if (res.status == 200) {
          this.overbitList = res.data
        }
      })
    },
    // 选择出生日期 改变周龄
    selectBir(value, row, index) {
      if (value) {
        let today = moment(new Date()).format("YYYY-MM-DD");
        let birDay = moment(value).format("YYYY-MM-DD");
        let diffTime = moment(today).diff(moment(birDay), 'days');
        let weeksAge = diffTime / 7
        row.weekDays = Math.round(weeksAge)
      }
    },
    // 编辑
    edit(row, index) {
      if (row.aniQuarResult.overbit == '' && row.aniQuarResult.show) {
        this.$message.warning("请选择耳号！")
        return;
      }
      // orderDetailId
      this.overbitList.forEach((item, index) => {
        if (item.orderDetailId == row.aniQuarResult.orderDetailId) {
          item.disabled = true//禁用掉已选耳号
        }
      })
      this.publicShow(row)
    },
    // 保存
    save(row, index) {
      let params = {
        id: row.id,
        remark: row.remark
      };
      this.$putJson("/mate/aniCage", params).then(res => {
        this.publicShow(row, index);
        this.$message.success('修改成功！')
      })
    },

    copy(val, index) {
      // console.log(data)
      //新增空对象
      /*let obj = JSON.parse(JSON.stringify(val));
      for (let key in obj) {
        obj[key] = '';
      }
      obj.show = true;
      obj.cageId = val.id;
      obj.typeList = data.typeList;*/
      // this.cage.aniBase.splice(index, 0, obj)
    },
    //关闭 or 开启，/**公共代码**/
    publicShow(row, index) {
      row.aniQuarResult.show = !row.aniQuarResult.show
      this.$set(row.aniQuarResult, index, row)
    },
    // 删除
    deleteA(row, index) {
      this.$del("/mate/aniCage/" + row.id).then(res => {
        this.publicDel(row, index);
        this.$message.success('删除成功！')
      }).catch(() => {
        this.$message.error("删除失败！")
      })
    },
    cageSubmit(type) {
      let params = {
        subId: this.activeInfo.id,
        // state: type,
        // isLabAni:this.detail.isLabAni//是否来自实验室
      }
      this.$put("/subject/mate/state", params).then(res => {
        this.$message.success("操作成功！")
      }).catch(() => {
        this.$message.error("操作失败")
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";
</style>
